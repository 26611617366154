import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { RoutePropagator as ShopifyRoutePropagator } from '@shopify/app-bridge-react'

export default function RoutePropagator() {
  const router = useRouter()
  const { asPath } = router
  const appBridge = useAppBridge()

  // Subscribe to appBridge changes -
  // captures appBridge urls and sends them to Next.js router.
  // Use useEffect hook to load once when component mounted
  useEffect(() => {
    appBridge.subscribe(Redirect.Action.APP, (payload) => {
      router.push(payload.path)
    })
  }, [])

  return appBridge && asPath ? <ShopifyRoutePropagator location={asPath} /> : null
}
