import '@/styles/globals.css'
import '@shopify/polaris/build/esm/styles.css'
import '@/styles/topbar.component.css'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Head from 'next/head'

import { AppProvider, Frame } from '@shopify/polaris'
import EmbeddedApp from '@/wrapper/EmbeddedApp'
import Script from 'next/script'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const currentPath = router.pathname
  const notEmbedPaths = new Set(['/paypal-redirect', '/paypal-prepare', '/404'])
  const polarisConfig = {
    Polaris: {
      ResourceList: {
        sortingLabel: 'Sort by',
        defaultItemSingular: 'item',
        defaultItemPlural: 'items',
        showing: 'Showing {itemsCount} {resource}',
        Item: {
          viewItem: 'View details for {itemName}',
        },
      },
      Common: {
        checkbox: 'checkbox',
      },
      DatePicker: {
        previousMonth: 'Show previous month, {previousMonthName} {showPreviousYear}',
        nextMonth: 'Show next month, {nextMonth} {nextYear}',
        today: 'Today ',
        start: 'Start of range',
        end: 'End of range',
        months: {
          january: 'January',
          february: 'February',
          march: 'March',
          april: 'April',
          may: 'May',
          june: 'June',
          july: 'July',
          august: 'August',
          september: 'September',
          october: 'October',
          november: 'November',
          december: 'December',
        },
        days: {
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
        },
        daysAbbreviated: {
          monday: 'Mo',
          tuesday: 'Tu',
          wednesday: 'We',
          thursday: 'Th',
          friday: 'Fr',
          saturday: 'Sa',
          sunday: 'Su',
        },
      },
    },
  }

  useEffect(() => {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)
    mixpanel.set_config({ persistence: 'localStorage', ignore_dnt: true })
  }, [])

  return (
    <>
      <AppProvider i18n={polarisConfig}>
        <Frame>
          <Head>
            <title>Bulk Fulfill by Upatra</title>
            <meta name="description" content="Generated by create next app" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href="/favicon.ico" />
          </Head>

          {notEmbedPaths.has(currentPath) && <Component {...pageProps} />}

          {!notEmbedPaths.has(currentPath) && (
            <EmbeddedApp>
              <Component {...pageProps} />
            </EmbeddedApp>
          )}
        </Frame>
      </AppProvider>
      <Script id="install-clarity">
        {`
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "j3ynp2uj28");
        `}
      </Script>
    </>
  )
}
