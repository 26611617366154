import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react'
import { AppConfigV2 } from '@shopify/app-bridge'
import { AuthProvider, PlanProvider } from '@/context'
import RoutePropagator from '@/components/RoutePropagator'

export default function EmbeddedApp({ children }) {
  const SHOPIFY_APP_CLIENT_ID = process.env.NEXT_PUBLIC_SHOPIFY_APP_CLIENT_ID || ''
  const TEST_SHOP = process.env.NEXT_PUBLIC_TEST_SHOP

  const router = useRouter()
  const [host, setHost] = useState<string>()
  const [shop, setShop] = useState<string>()

  useEffect(() => {
    const url = new URL(window.location.href)
    let paramHost = url.searchParams.get('host')
    let paramShop = url.searchParams.get('shop')

    if ((!paramHost || !paramShop) && TEST_SHOP) {
      paramHost = btoa(`admin.shopify.com/store/${TEST_SHOP}`).replace('==', '')
      paramShop = `${TEST_SHOP}.myshopify.com`
      router.query.host = paramHost
      router.query.shop = paramShop
      router.push(router).then(() => router.reload())
    }

    if (paramHost) setHost(paramHost)
    if (paramShop) setShop(paramShop)
  }, [])

  const appBridgeConfig: AppConfigV2 = {
    host: host || '',
    apiKey: SHOPIFY_APP_CLIENT_ID,
    // forceRedirect: TEST_SHOP ? false : true,
    forceRedirect: false,
  }

  return (
    <>
      {host && shop && (
        <AppBridgeProvider config={appBridgeConfig}>
          <AuthProvider host={host} shop={shop}>
            <PlanProvider>
              <RoutePropagator />
              {children}
            </PlanProvider>
          </AuthProvider>
        </AppBridgeProvider>
      )}
    </>
  )
}
