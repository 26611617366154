export type FileRow = { [key: string]: string }

export type FulfillmentResponse = {
  rowId: string
  orderId?: number
  result: { type: string; message: string }
  type?: string
}

export type BaseTableItem = {
  _rowNumber: number
  _rowId: string
  _orderLevelId?: string
}

export type TableItem = BaseTableItem & {
  orderName?: string
  orderNameErrMsg?: string
  trackingNumbers?: string[]
  normalizeTrackingNumberStr?: string
  trackingCompany?: string
  trackingUrlPrefix?: string
  trackingUrlPrefixErrMsg?: string
  trackingUrls?: string[]
  sku?: string
  quantity?: string
  quantityErrMsg?: string
  shopifyStatus?: string
  orderId?: string
  shopifyResponse?: string
  paypalStatus?: string
  paypalResponse?: string
}

export enum PlanType {
  FREE,
  T2P,
  ADVANCE,
}

export const PlanIdMapper = {
  BF_V2_T2P_MONTHLY: PlanType.T2P,
  BF_V2_T2P_ANNUALLY: PlanType.T2P,
  BF_V2_ADVANCE_MONTHLY: PlanType.ADVANCE,
  BF_V2_ADVANCE_ANNUALLY: PlanType.ADVANCE,
}

export type Plan = {
  planId: string
  name: string
  description: string
  amount: string
}
