import axios, { AxiosResponse } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

import { Plan } from '@/domain/type/common'

const TEST_STORES = ['hoangvv-upatra.myshopify.com', 'testsub02.myshopify.com']

// Const
const APPHUB_API_URL = process.env.NEXT_PUBLIC_APPHUB_URL
const SHOPIFY_APP_CODE = process.env.NEXT_PUBLIC_APP_CODE

const maxRetries = 3
const retryInterval = 1000
let retryCount = 0

const apiInstance = axios.create({
  baseURL: APPHUB_API_URL,
})
apiInstance.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = camelizeKeys(response.data)
    }

    retryCount = 0
    return response
  },
  (error) => {
    const { config, response } = error
    if (response && (response.status >= 500 || !response.status)) {
      if (retryCount < maxRetries) {
        retryCount++
        return new Promise((resolve) =>
          setTimeout(() => resolve(apiInstance(config)), retryInterval)
        )
      }
    }

    retryCount = 0
    return Promise.reject(error)
  }
)
apiInstance.interceptors.request.use(
  (config) => {
    if (config.data) {
      config.data = decamelizeKeys(config.data)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export async function exchangePaypalToken(code: string, shop: string) {
  try {
    const response = await apiInstance.get(
      `/paypal_auth?code=${code}&shop=${shop}&app_code=bulk_fulfill`
    )

    return {
      paypalName: response.data.paypalName,
      email: response.data.email,
    }
  } catch (e) {
    const error = e as AxiosResponse
    if (axios.isAxiosError(error)) {
      if (error.status == 422) {
        throw new UnprocessableEntity(error.data.data.errorDescription)
      } else {
        throw new Error(error.response?.data)
      }
    } else {
      console.error(error)
    }

    return { error }
  }
}

export async function getActivePlan(
  getSessionToken: () => Promise<string>,
  shopifyDomain: string,
  isTest: boolean
): Promise<any> {
  const upatraToken = await getSessionToken()
  const config = {
    headers: { Authorization: `Bearer ${upatraToken}` },
  }

  try {
    const response = await apiInstance.get(`/${SHOPIFY_APP_CODE}/shop_plans`, config)

    const plan = response.data.plan
    if (!plan) return null

    if (TEST_STORES.includes(shopifyDomain)) isTest = true

    return plan.test && !isTest ? null : plan
  } catch (e) {
    const error = e as AxiosResponse
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data)
    } else {
      console.error(error)
    }

    throw e
  }
}

export async function cancelPlan(getSessionToken: () => Promise<string>) {
  const upatraToken = await getSessionToken()
  const config = {
    headers: { Authorization: `Bearer ${upatraToken}` },
  }

  try {
    await apiInstance.delete(`/${SHOPIFY_APP_CODE}/shop_plans`, config)
  } catch (e) {
    console.log(e)
    const error = e as AxiosResponse
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data)
    } else {
      console.error(error)
    }

    throw e
  }
}

export async function getAllPlan(): Promise<Plan[]> {
  try {
    const response = await apiInstance.get(`/${SHOPIFY_APP_CODE}/plans`)

    return response.data.plans.map((plan) => {
      return {
        ...plan,
      }
    })
  } catch (e) {
    const error = e as AxiosResponse
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data)
    } else {
      console.error(error)
    }

    throw e
  }
}

export async function activePlan(
  getSessionToken: () => Promise<string>,
  planId: string,
  returnUrl: string,
  shopifyDomain: string,
  isTest: boolean
) {
  const upatraToken = await getSessionToken()

  const config = {
    headers: { Authorization: `Bearer ${upatraToken}` },
  }

  if (TEST_STORES.includes(shopifyDomain)) isTest = true

  const body = {
    planId,
    returnUrl,
    isTest,
  }

  try {
    const response = await apiInstance.post(`/${SHOPIFY_APP_CODE}/shop_plans`, body, config)
    return response.data.confirmationUrl as string
  } catch (e) {
    console.log(body)
    console.log(e)
    const error = e as AxiosResponse
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data)
    } else {
      console.error(error)
    }

    throw e
  }
}

export class UnprocessableEntity extends Error {
  constructor(message) {
    super(message)
    this.name = 'UnprocessableEntity'
  }
}

export async function getConnectedPaypal(getSessionToken: () => Promise<string>) {
  const upatraToken = await getSessionToken()
  const config = {
    headers: { Authorization: `Bearer ${upatraToken}` },
  }

  try {
    const response = await apiInstance.get('/paypal_accounts', config)
    const result = response.data.paypalAccounts
    return result ? result : null
  } catch (e) {
    const error = e as AxiosResponse
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data)
    } else {
      console.error(error)
    }

    throw e
  }
}

export async function addPaypalToStore(code: string, shop: string) {
  try {
    const response = await apiInstance.get(
      `/paypal_auth?code=${code}&shop=${shop}&app_code=bulk_fulfill`
    )
    return true
  } catch (e) {
    const error = e as AxiosResponse
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data)
    } else {
      console.error(error)
    }

    throw e
  }
}

export async function removeConnectedPaypal(
  getSessionToken: () => Promise<string>,
  paypalUserId: string
) {
  const upatraToken = await getSessionToken()
  const config = {
    headers: { Authorization: `Bearer ${upatraToken}` },
  }

  try {
    await apiInstance.delete(`/paypal_accounts/${paypalUserId}`, config)
    return true
  } catch (e) {
    const error = e as AxiosResponse
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data)
    } else {
      console.error(error)
    }

    throw e
  }
}

export async function upatraAuth(params: string) {
  try {
    const response = await apiInstance.get(`/${SHOPIFY_APP_CODE}/auth${params}`)

    return {
      shopifyDomain: response.data.domain,
      upatraToken: response.data.accessToken,
    }
  } catch (e) {
    const error = e as AxiosResponse
    if (axios.isAxiosError(error)) {
      if (error.response && [404, 422, 401, 403].includes(error.response.status)) {
        return null
      } else {
        throw new Error(error.response?.data)
      }
    } else {
      console.error(error)
    }

    return { error }
  }
}
