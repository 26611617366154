import { createContext, ReactNode, useContext, useState, useEffect } from 'react'
import { useAppBridge } from '@shopify/app-bridge-react'
import { getSessionToken as getShopifySessionToken } from '@shopify/app-bridge/utilities'
import mixpanel from 'mixpanel-browser'

import {
  delay,
  redirectToUrl,
  generateShopifyAuthorizeUrl,
  normalizeShopifyDomain,
  getShopifyAdminAppUrlV2,
} from '@/utilities/misc'
import { upatraAuth } from '@/utilities/apphubApi'

type AuthProviderProps = {
  host: string
  shop: string
  children: ReactNode
}

export type AuthContext = {
  host: string
  shopifyDomain: string
  isLoading: boolean
  getSessionToken: () => Promise<string>
}

const AuthContext = createContext({} as AuthContext)

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ host, shop, children }: AuthProviderProps) {
  const app = useAppBridge()
  const shopifyDomain = normalizeShopifyDomain(shop)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      if (!window.location.search) return

      mixpanel.identify(shopifyDomain)

      const result = await upatraAuth(window.location.search)
      await delay(300)
      if (!result || !result.shopifyDomain) {
        const authorizeUrl = generateShopifyAuthorizeUrl(shopifyDomain)
        mixpanel.track('Permission Requested', {}, redirectToUrl(authorizeUrl))
      } else {
        const url = new URL(window.location.href)
        const installed = url.searchParams.get('installed')
        if (installed) mixpanel.track('Installed')

        if (window.self === window.top && !window.location.href.includes(process.env.NEXT_PUBLIC_LOCALHOST_PATTERN || 'localhost')) {
          redirectToUrl(getShopifyAdminAppUrlV2(shopifyDomain))
        }

        setIsLoading(false)
      }
    })()
  }, [shopifyDomain])

  const getSessionToken = async () => {
    const envToken = process.env.NEXT_PUBLIC_SHOP_TOKEN
    if (envToken) return envToken

    const maxRetries = 5
    let retryCount = 0

    while (retryCount < maxRetries) {
      try {
        return await getShopifySessionToken(app)
      } catch (error) {
        console.error(`Attempt ${retryCount + 1} failed: ${error.message}`)
        retryCount++

        if (retryCount === maxRetries) {
          console.error('Max retries reached. Giving up.')
          throw error // Should we throw or reload the page?
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setTimeout(() => {}, 500)
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        host,
        isLoading,
        shopifyDomain,
        getSessionToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
