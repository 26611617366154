import { useState, createContext, useContext, ReactNode } from 'react'

export type MultiStepContext = {
  currentStepIndex: number
  isFirstStep: boolean
  isLastStep: boolean
  next: () => void
  back: () => void
  goto: (stepName: string) => void
}
const MultiStepContext = createContext({} as MultiStepContext)

export function useMultiStep() {
  return useContext(MultiStepContext)
}

type MultiStepProviderProps = {
  steps: { name: string; node: ReactNode }[]
}

export function MultiStepProvider({ steps }: MultiStepProviderProps) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const next = () => {
    setCurrentStepIndex((i) => {
      if (i >= steps.length - 1) return i
      return i + 1
    })
  }

  const back = () => {
    setCurrentStepIndex((i) => {
      if (i <= 0) return i
      return i - 1
    })
  }

  const goto = (stepName: string) => {
    const index = steps.findIndex((s) => s.name == stepName)
    setCurrentStepIndex(index)
  }

  return (
    <MultiStepContext.Provider
      value={{
        currentStepIndex,
        isFirstStep: currentStepIndex === 0,
        isLastStep: currentStepIndex === steps.length - 1,
        next,
        back,
        goto,
      }}
    >
      {steps[currentStepIndex].node}
    </MultiStepContext.Provider>
  )
}
