import * as crypto from 'crypto'

export const isBrowser = typeof window !== 'undefined'

export const isNavigator = typeof navigator !== 'undefined'

export const isFrame = isBrowser && window !== window.parent

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function generateShopifyAuthorizeUrl(shopifyDomain: string): string {
  const normDomain = normalizeShopifyDomain(shopifyDomain)
  const urlParts = [
    `https://${normDomain}/admin/oauth/authorize`,
    `?client_id=${process.env.NEXT_PUBLIC_SHOPIFY_APP_CLIENT_ID}`,
    `&scope=${process.env.NEXT_PUBLIC_SCOPE}`,
    `&redirect_uri=${process.env.NEXT_PUBLIC_APPHUB_URL}/${process.env.NEXT_PUBLIC_APP_CODE}/exchange_token`,
  ]

  return urlParts.join('')
}

// This not work
// export function generateShopifyAuthorizeUrlV2(shopifyDomain: string): string {
//   const shopSlug = getShopSlug(shopifyDomain)
//   const urlParts = [
//     `https://admin.shopify.com/store/${shopSlug}/oauth`,
//     `?client_id=${process.env.NEXT_PUBLIC_SHOPIFY_APP_CLIENT_ID}`,
//     `&scope=${process.env.NEXT_PUBLIC_SCOPE}`,
//     `&redirect_uri=${process.env.NEXT_PUBLIC_APPHUB_URL}/${process.env.NEXT_PUBLIC_APP_CODE}/exchange_token`,
//   ]

//   return urlParts.join('')
// }

export function normalizeShopifyDomain(shopifyDomain: string): string {
  const lowerCase = shopifyDomain.toLowerCase().trim()
  const shopifyRegex = /.+\.myshopify\.com$/
  const httpRegex = /^http(s):\/\//

  const nonHttpUrl = lowerCase.replace(httpRegex, '')

  if (nonHttpUrl.match(shopifyRegex)) return nonHttpUrl

  return `${nonHttpUrl}.myshopify.com`
}

export function getShopSlug(shopifyDomain: string): string {
  const normDomain = normalizeShopifyDomain(shopifyDomain)
  const shopifyRegex = /\.myshopify\.com$/
  const httpRegex = /^http(s):\/\//
  const nonHttpUrl = normDomain.replace(httpRegex, '')
  const shopSlug = nonHttpUrl.replace(shopifyRegex, '')

  return shopSlug
}

export function generateRequestId(contents: Array<string>): string {
  return crypto.createHash('md5').update(contents.join('|')).digest('hex')
}

export function getShopifyAdminAppUrl(shopifyDomain: string): string {
  const normDomain = normalizeShopifyDomain(shopifyDomain)
  return `https://${normDomain}/admin/apps/${process.env.NEXT_PUBLIC_SHOPIFY_APP_NAME}`
}

export function getShopifyAdminAppUrlV2(shopifyDomain: string): string {
  const slug = getShopSlug(shopifyDomain)
  return `https://admin.shopify.com/store/${slug}/apps/${process.env.NEXT_PUBLIC_SHOPIFY_APP_NAME}`
}

export function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0
  } else {
    return value === '' || value == null
  }
}

export function chunkArray(arr: any[], chunkSize = 1, cache: any[] = []) {
  const tmp: any[] = [...arr]
  if (chunkSize <= 0) return cache
  while (tmp.length) cache.push(tmp.splice(0, chunkSize))
  return cache
}

export function generateOrderLink(shopifyDomain: string, orderId: string) {
  return `https://admin.shopify.com/store/${getShopSlug(shopifyDomain)}/orders/${orderId}`
}

export function redirectToUrl(url: string) {
  window.top ? (window.top.location.href = url) : (window.location.href = url)
}

export const nonLatinTrackingCompanyMapper = {
  '\xE3\x83\xA4\xE3\x83\x9E\xE3\x83\x88\xE9\x81\x8B\xE8\xBC\xB8': 'ヤマト運輸',
  '\xE4\xBD\x90\xE5\xB7\x9D\xE6\x80\xA5\xE4\xBE\xBF': '佐川急便',
  '\xE6\x97\xA5\xE6\x9C\xAC\xE9\x83\xB5\xE4\xBE\xBF': '日本郵便',
}

export const convertSecondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  let time = ''
  if (hours > 0) {
    time += `${hours}h `
  }
  if (minutes > 0) {
    time += `${minutes}m `
  }
  time += `${remainingSeconds}s`

  return time
}

export const removeUnexistedColumns = (headerMap: Record<string, string>, fileHeader: string[]) => {
  return Object.fromEntries(
    Object.entries(headerMap).filter(([, value]) => fileHeader.includes(value))
  )
}
